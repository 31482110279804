.loader{
    
    display: flex;
    align-items: center;
    justify-content: center;
}
.loader div{
    width: 5px;
    height: 5px;
    background: #fff;
    border-radius: 50%;
    margin: 0 10px;
}

.loader .dot-1{
    animation: bounce 1.6s -0.3s linear infinite;
}

.loader .dot-2{
    animation: bounce 1.6s -0.16s linear infinite;
}

.loader .dot-3{
    animation: bounce 1.6s -0.3s linear infinite;
}

@keyframes bounce {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40%{
        transform: scale(2.0);
    }
}