.task-form{
    font-family: body;
}

.task-table{
    overflow: hidden;
}

.task-table tr{
    font-family: content-body;
    font-size: 1.1rem;
}

.loading-img{
   display: flex;
   justify-content: center;
}

.loading-img img{
    width: 8rem;
}

.toast{
    font-family: body;
    color: #fff;
}

.toast .me-auto{
    font-size: 1.3rem;
}

.task-table .table-icons{
    font-size: 1.3rem;
    cursor: pointer;
}

.task-table .table-icons span:nth-of-type(3){
    color: var(--green);
}